import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { ErrorResponse, GetSkuResponse } from '@/types/warehouse';
import api from '@/utils/api';
import { AxiosError } from 'axios';
import { ImageOutlined } from '@mui/icons-material';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import Scrollbar from '@/components/Scrollbar';
import { unixToFormattedDate } from '@/utils/formatters';

interface SkuDetailModalProps {
  id: string | null;
  close: () => void;
}

export default function SkuDetailModal({ id, close }: SkuDetailModalProps) {
  const theme = useTheme();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [sku, setSku] = useState<GetSkuResponse['sku'] | null>(null);

  const fetchSku = async (id: string) => {
    setLoading(true);
    setError(null);
    setSku(null);

    try {
      const { data } = await api.get<GetSkuResponse | ErrorResponse>(
        `/warehouse/fbc/sku/${id}`,
      );

      if ('errors' in data) {
        throw new Error(data.errors[0]?.message ?? '');
      }

      setSku(data.sku);
    } catch (e) {
      setError(
        e instanceof AxiosError
          ? e.response?.data?.message
          : e instanceof Error
            ? e.message
            : `${e}`,
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id !== null) {
      fetchSku(id);
    }
  }, [id]);

  return (
    <Modal
      open={id !== null}
      onClose={close}
      aria-labelledby="sku-detail-modal-title"
    >
      <Container
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        disableGutters
        maxWidth="lg"
      >
        <Card>
          <CardHeader
            id="sku-detail-modal-title"
            title={id}
            action={
              <IconButton onClick={close}>
                <CloseTwoToneIcon fontSize="small" />
              </IconButton>
            }
            sx={{ padding: theme.spacing(2, 3) }}
          />
          <Divider />
          <CardContent>
            {loading ? (
              <Box sx={{ textAlign: 'center' }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {error !== null && (
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    Failed to load SKU: {error || 'something went wrong'}.
                    Please try again.
                  </Alert>
                )}
                {sku !== null && (
                  <Scrollbar autoHeight autoHeightMax={'75vh'}>
                    <Grid container>
                      <Grid item xs={12} md={6} paddingRight={theme.spacing(3)}>
                        <Stack direction="row" alignItems="center">
                          {sku.imageUrl ? (
                            <img
                              src={sku.imageUrl}
                              alt="SKU Image"
                              width="64px"
                            />
                          ) : (
                            <ImageOutlined fontSize="large"></ImageOutlined>
                          )}
                          <div style={{ marginLeft: theme.spacing(1) }}>
                            <Typography variant="h4" component="h4">
                              {sku.name}
                            </Typography>
                            {sku.category}
                          </div>
                        </Stack>

                        <TableContainer sx={{ marginTop: theme.spacing(2) }}>
                          <Table size="small">
                            <TableBody>
                              <TableRow>
                                <TableCell variant="head">
                                  Country Of Origin
                                </TableCell>
                                <TableCell>{sku.countryOfOrigin}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">
                                  Cost Price ($)
                                </TableCell>
                                <TableCell>{sku.costPrice}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">
                                  Retail Price ($)
                                </TableCell>
                                <TableCell>{sku.retailPrice}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">Bulky</TableCell>
                                <TableCell>
                                  {sku.bulky ? 'Yes' : 'No'}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">
                                  Dangerous Goods
                                </TableCell>
                                <TableCell>
                                  {sku.dangerousGoods ? 'Yes' : 'No'}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">
                                  Has Expiry Date?
                                </TableCell>
                                <TableCell>
                                  {sku.expiry ? 'Yes' : 'No'}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">
                                  Has Best Before Date?
                                </TableCell>
                                <TableCell>
                                  {sku.bestBefore ? 'Yes' : 'No'}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">
                                  Expiry/BB date
                                </TableCell>
                                <TableCell>
                                  {unixToFormattedDate(sku.expiryDate)}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">
                                  Date Created
                                </TableCell>
                                <TableCell>
                                  {unixToFormattedDate(sku.createdAt)}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">
                                  Date Updated
                                </TableCell>
                                <TableCell>
                                  {unixToFormattedDate(sku.updatedAt)}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid item xs={12} md={6} sx={{ mt: { xs: 2, sm: 0 } }}>
                        <TableContainer>
                          <Typography
                            variant="h5"
                            paddingBottom={theme.spacing(1)}
                          >
                            Identifiers
                          </Typography>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>Type</TableCell>
                                <TableCell>Value</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {sku.identifiers.map((identifier) => (
                                <TableRow>
                                  <TableCell>{identifier.type}</TableCell>
                                  <TableCell>{identifier.identifier}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>

                        <TableContainer sx={{ margin: theme.spacing(2, 0) }}>
                          <Typography
                            variant="h5"
                            paddingBottom={theme.spacing(1)}
                          >
                            Measurements
                          </Typography>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>Weight (kg)</TableCell>
                                <TableCell>Height (cm)</TableCell>
                                <TableCell>Length (cm)</TableCell>
                                <TableCell>Width (cm)</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {sku.footprint?.length ? (
                                sku.footprint.map((footprint) => (
                                  <TableRow>
                                    <TableCell>{footprint.weight}</TableCell>
                                    <TableCell>{footprint.height}</TableCell>
                                    <TableCell>{footprint.length}</TableCell>
                                    <TableCell>{footprint.width}</TableCell>
                                  </TableRow>
                                ))
                              ) : (
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    variant="footer"
                                    colSpan={4}
                                  >
                                    No measurements found for this SKU.
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>

                        <TableContainer>
                          <Typography
                            variant="h5"
                            paddingBottom={theme.spacing(1)}
                          >
                            Inventory
                          </Typography>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>Warehouse</TableCell>
                                <TableCell>On Hand</TableCell>
                                <TableCell>On Sale</TableCell>
                                <TableCell>Unallocated</TableCell>
                                <TableCell>On PO</TableCell>
                                <TableCell>Bulky</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {sku.inventory.map((inventory) => (
                                <TableRow>
                                  <TableCell>
                                    {inventory.warehouse_code}
                                  </TableCell>
                                  <TableCell>
                                    {inventory.quantity_on_hand}
                                  </TableCell>
                                  <TableCell>
                                    {inventory.quantity_on_sale}
                                  </TableCell>
                                  <TableCell>
                                    {inventory.quantity_unallocated}
                                  </TableCell>
                                  <TableCell>
                                    {inventory.quantity_on_po}
                                  </TableCell>
                                  <TableCell>
                                    {inventory.oversized ? 'Yes' : 'No'}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Scrollbar>
                )}
              </>
            )}
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
}
