import { User } from '@/types/user';
import { api } from '../utils/api';
import React, {
  useContext,
  createContext,
  useState,
  ReactNode,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';

type AuthContextType = {
  user: User | null;
  miraklUrl: string | null;
  setUser: (user: User) => void;
  isAuthenticated: boolean;
  signOut: () => void;
};

type AuthProviderProps = {
  children: ReactNode;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const [miraklUrl, setMiraklUrl] = useState<string | null>(null);
  const [isPending, setIsPending] = useState<boolean>(true);

  const isAuthenticated = useMemo(
    () => !isPending && user !== null,
    [isPending, user],
  );

  const signOut = useCallback(async () => {
    setIsPending(true);

    try {
      await api.post('/logout');
      setUser(null);
    } catch (error) {
    } finally {
      navigate('/login');
      setIsPending(false);
    }
  }, [navigate]);

  const fetchUser = useCallback(async () => {
    setIsPending(true);
    setUser(null);

    try {
      const { data } = await api.get<User & { miraklUrl?: string }>('/me');

      setMiraklUrl(data.miraklUrl || null);
      delete data.miraklUrl;

      setUser(data);
    } catch (e) {
    } finally {
      setIsPending(false);
    }
  }, []);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  /**
   * Handles redirecting users to the desired page after login.
   * @note Does not work locally with React.StrictMode enabled due to double-fetching from session storage.
   */
  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    const redirectTo = sessionStorage.getItem('redirectTo');
    if (redirectTo === null) {
      return;
    }

    sessionStorage.removeItem('redirectTo');

    navigate(redirectTo, { replace: true });
  }, [isAuthenticated, navigate]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        miraklUrl,
        setUser,
        signOut,
      }}
    >
      {!isPending ? (
        children
      ) : (
        <Grid container component="main" sx={{ height: '100vh' }}>
          <Grid item xs={12} alignItems="center" justifyContent="center">
            <LoadingButton
              size="large"
              fullWidth
              sx={{ height: '100%' }}
              loading={true}
              disabled={true}
            />
          </Grid>
        </Grid>
      )}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth cannot be used outside of AuthProvider');
  }

  return context;
};

export { AuthContext, AuthProvider, useAuth };
