import { createCurrencyFormatter } from '@/utils/formatters';
import { api } from '@/utils/api';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useState } from 'react';
import { RefreshTwoTone } from '@mui/icons-material';
import { AxiosError } from 'axios';

export default function RetailMediaCreditsBalance() {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [accounts, setAccounts] = useState<
    | {
        type: string;
        balance: { currency: string; amount_micro: string };
      }[]
    | null
  >(null);

  const load = useCallback(async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await api.get('/retail-media/credits');
      setAccounts(response.data);
    } catch (e) {
      setError(
        e instanceof AxiosError
          ? e.response?.data?.message
          : e instanceof Error
            ? e.message
            : `${e}`,
      );
    } finally {
      setLoading(false);
    }
  }, [loading]);

  const formatBalance = ({
    currency,
    amount_micro,
  }: {
    currency: string;
    amount_micro: string;
  }): string =>
    `${createCurrencyFormatter(currency).format(
      parseInt(amount_micro) / 1000000,
    )}`;

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardHeader
        title="Current Balance"
        action={
          <LoadingButton size="small" disabled={loading} onClick={load}>
            <RefreshTwoTone />
          </LoadingButton>
        }
      />
      <Divider />
      <CardContent>
        {error !== null && (
          <Alert severity="error" onClose={() => setError(null)}>
            <AlertTitle>Error</AlertTitle>
            Failed to load credits: {error || 'something went wrong'}. Please
            try again.
          </Alert>
        )}

        <Stack
          direction="row"
          justifyContent="space-around"
          alignContent="center"
          sx={{ width: '100%' }}
          spacing={2}
        >
          {loading ? (
            <LoadingButton size="large" loading={true} disabled={true} />
          ) : (
            accounts !== null &&
            accounts.map(({ type, balance }) => (
              <Box sx={{ textAlign: 'center' }} key={type}>
                <Typography variant="caption" gutterBottom>
                  {type[0] + type.replace(/_/g, '').substring(1).toLowerCase()}
                </Typography>
                <Typography variant="h6">{formatBalance(balance)}</Typography>
              </Box>
            ))
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
