import { ReactNode } from 'react';
import { Box, Container, useTheme } from '@mui/material';

import Logo from '@/components/Logo';
import Footer from '@/components/Footer';

interface FullPageLayoutProps {
  children?: ReactNode;
}

export default function FullPageLayout({ children }: FullPageLayoutProps) {
  const theme = useTheme();

  return (
    <Container
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Logo />
        <Box marginTop={theme.spacing(3)}>{children}</Box>
        <Footer />
      </Box>
    </Container>
  );
}
