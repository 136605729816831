import { Card, CardActionArea, CardContent, styled } from '@mui/material';
import { useMemo } from 'react';
import { useDropzone, FileRejection, DropEvent, Accept } from 'react-dropzone';

export type OnDropProps = <T extends File>(
  acceptedFiles: T[],
  fileRejections: FileRejection[],
  event: DropEvent,
) => void;

export interface DropzoneProps {
  onDrop?: OnDropProps;
  accept?: Accept;
}

const DragDropCard = styled(Card)(
  ({ theme }) => `
        border: ${theme.colors.primary.main} dashed 1px;
        height: 100%;
        color: ${theme.colors.primary.main};
        box-shadow: none;

        .MuiCardActionArea-root {
          height: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
        }

        .MuiTouchRipple-root {
          opacity: .2;
        }

        &:hover {
          border-color: ${theme.colors.alpha.black[100]};
        }
`,
);

const focusedStyle = {
  borderColor: '#428bca',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#d9534f',
};

export default function Dropzone({ onDrop, accept }: DropzoneProps) {
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragActive,
    isDragReject,
  } = useDropzone({
    accept,
    onDrop,
  });

  const style = useMemo(
    () => ({
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <DragDropCard className="dropzone" {...getRootProps({ style })}>
      <CardActionArea sx={{ px: 1 }}>
        <CardContent>
          <input className="input-zone" {...getInputProps()} />
          <div className="text-center">
            {isDragActive && !isDragReject ? (
              <p className="dropzone-content">Release to drop the file here</p>
            ) : isDragActive && isDragReject ? (
              <p className="dropzone-content">Invalid file type</p>
            ) : (
              <p className="dropzone-content">
                Drag and drop CSV here, or click to select a file
              </p>
            )}
          </div>
        </CardContent>
      </CardActionArea>
    </DragDropCard>
  );
}
