import { Card, Divider } from '@mui/material';
import FilterInput, {
  getEmptyFilterFromFormConfig,
  InputConfig,
} from './FilterInput';
import ItemTable, { TableColumnConfig, Pagination, Sort } from './ItemTable';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';

export interface FilteredListProps<Filter extends Record<string, any>, Item> {
  filterForm: InputConfig[][];
  columns: TableColumnConfig<Item>[];
  rowsPerPageOptions: number[];
  defaultSortKey: string;
  loadItems: (
    filter: Filter,
    pagination: Pagination,
    sort: Sort,
  ) => Promise<{
    items: Item[];
    pagination: Pagination;
    sort: Sort;
  }>;
  getItemId: (item: Item) => string;
}

export default function FilteredList<Filter extends Record<string, any>, Item>({
  filterForm,
  columns,
  rowsPerPageOptions,
  defaultSortKey,
  loadItems,
  getItemId,
}: FilteredListProps<Filter, Item>) {
  const emptyFilter: Record<string, any> =
    getEmptyFilterFromFormConfig(filterForm);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [filter, setFilter] = useState(emptyFilter as Filter);
  const [items, setItems] = useState<Item[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    page: 0,
    limit: rowsPerPageOptions[0],
    count: 0,
  });
  const [sort, setSort] = useState<Sort>({
    key: defaultSortKey,
    direction: 'DESC',
  });

  const handleLoadItems = async (filter: Filter) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    setError(null);
    setItems([]);

    try {
      const data = await loadItems(filter, pagination, sort);
      setPagination(data.pagination);
      setItems(data.items);
    } catch (e) {
      setError(
        e instanceof AxiosError
          ? e.response?.data?.message
          : e instanceof Error
            ? e.message
            : `${e}`,
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleLoadItems(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.page, pagination.limit, sort]);

  return (
    <Card>
      <FilterInput
        filter={filter}
        onFilterChange={setFilter}
        onFormSubmit={handleLoadItems}
        formConfig={filterForm}
        isLoading={isLoading}
      />

      <Divider />

      <ItemTable
        isLoading={isLoading}
        error={error}
        emptyMessage="No results found!"
        items={items}
        getItemId={getItemId}
        columns={columns}
        sort={sort}
        onSortChange={setSort}
        pagination={pagination}
        onPaginationChange={setPagination}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </Card>
  );
}
