import { createCurrencyFormatter } from '@/utils/formatters';
import { api } from '@/utils/api';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputAdornment,
  OutlinedInput,
  TextField,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { FormEvent, useCallback, useMemo, useState } from 'react';
import { RetailMediaAccount } from '@/types/user';
import { AxiosError } from 'axios';

interface RetailMediaCreditsTopUpProps {
  vendor?: Pick<RetailMediaAccount, 'id' | 'name'> & { uuid: string };
  minimumAmount: number;
}

const MAX_TOPUP_AMOUNT = 50_000;

export default function RetailMediaCreditsTopUp({
  vendor,
  minimumAmount,
}: RetailMediaCreditsTopUpProps) {
  const [input, setInput] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const defaultFormatter = createCurrencyFormatter('AUD');

  const validationErrors = useMemo<string[]>(() => {
    const errors: string[] = [];
    if (!email && !input) {
      return errors;
    }

    if (vendor && !email.includes('@')) {
      errors.push('Please enter a valid e-mail address.');
    }

    const amount = parseFloat(input);
    if (amount < minimumAmount || amount > MAX_TOPUP_AMOUNT) {
      errors.push(
        `Please enter an amount between ${defaultFormatter.format(minimumAmount)} and ${defaultFormatter.format(MAX_TOPUP_AMOUNT)}.`,
      );
    }

    return errors;
  }, [email, input, defaultFormatter, minimumAmount, vendor]);

  const canSubmit = useMemo(() => {
    if (vendor && !email) {
      return false;
    }

    if (!input) {
      return false;
    }

    return validationErrors.length === 0;
  }, [email, input, vendor, validationErrors]);

  const purchase = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();

      if (!canSubmit || loading) {
        return false;
      }

      setError(null);
      setLoading(true);
      try {
        const response = await api.post(
          `/retail-media/${vendor ? 'vendor/' : ''}credits/purchase`,
          {
            amount: input,
            ...(vendor
              ? {
                  email,
                  uuid: vendor.uuid,
                }
              : {}),
          },
        );

        window.open(response.data.url, '_blank')?.focus();
        setInput('');
        setEmail('');
      } catch (e) {
        setError(
          e instanceof AxiosError
            ? e.response?.data?.message
            : e instanceof Error
              ? e.message
              : `${e}`,
        );
      } finally {
        setLoading(false);
      }
    },
    [canSubmit, input, loading, email, vendor],
  );

  return (
    <Card>
      <CardHeader title={'Top Up' + (vendor ? ` for ${vendor.name}` : '')} />
      <Divider />
      <CardContent>
        <Box>
          <Alert severity="info">
            Please note that the value entered below is exclusive of GST. GST
            amount will be displayed in the Stripe portal.
          </Alert>
        </Box>
        {error !== null && (
          <Box sx={{ mt: 1 }}>
            <Alert severity="error" onClose={() => setError(null)}>
              <AlertTitle>Error</AlertTitle>
              Failed to purchase credits: {error || 'something went wrong'}.
              Please try again.
            </Alert>
          </Box>
        )}
        <Box sx={{ my: 1 }}>
          <form onSubmit={purchase}>
            {vendor ? (
              <>
                <FormControl variant="outlined" sx={{ marginRight: 1 }}>
                  <TextField
                    type="email"
                    variant="outlined"
                    size="small"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={loading}
                    label="E-mail Address"
                  />
                </FormControl>
              </>
            ) : (
              <></>
            )}
            <FormControl variant="outlined">
              <OutlinedInput
                size="small"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">AUD</InputAdornment>
                }
                disabled={loading}
              />
            </FormControl>
            <LoadingButton
              type="submit"
              variant="outlined"
              size="small"
              sx={{ pb: 1, ml: 1 }}
              disabled={!canSubmit}
              loading={loading}
              onClick={purchase}
            >
              Purchase
            </LoadingButton>
          </form>
        </Box>
        <Box>
          <Typography variant="caption">
            {validationErrors.length ? (
              <Typography color="error" gutterBottom={false}>
                {validationErrors.join(' ')}
              </Typography>
            ) : (
              <></>
            )}
            You will be redirected to Stripe to complete payment.
            <br />
            Once payment is complete, it may take up to 24h for funds to be
            processed and credits to be added to your account.
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
