import { useAuth } from '../contexts/AuthContext';
import PageTitleWrapper from '../components/PageTitleWrapper';
import { Container, Grid, Typography } from '@mui/material';
import Footer from '../components/Footer';

export default function DashboardPage() {
  const { user } = useAuth();

  return (
    <>
      <title>Dashboard</title>
      <PageTitleWrapper>
        <Typography variant="h3" component="h3" gutterBottom>
          Dashboard
        </Typography>
        <Typography variant="subtitle2">Hello, {user?.email}.</Typography>
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}></Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}
