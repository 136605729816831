import { Box, Container } from '@mui/material';
import Copyright from '../Copyright';

export default function Footer() {
  return (
    <Container sx={{ my: 3 }}>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Copyright></Copyright>
      </Box>
    </Container>
  );
}
