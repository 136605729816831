import { ReactNode } from 'react';
import { Box, Container, useTheme } from '@mui/material';

interface PageTitleWrapperProps {
  children?: ReactNode;
}

export default function PageTitleWrapper({ children }: PageTitleWrapperProps) {
  const theme = useTheme();

  return (
    <Box padding={theme.spacing(4)} className="MuiPageTitle-wrapper">
      <Container maxWidth="lg">{children}</Container>
    </Box>
  );
}
