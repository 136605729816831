import { useState, createContext, ReactNode, useContext } from 'react';

export const SidebarContext = createContext<
  | {
      sidebarOpen: boolean;
      toggleSidebar: () => void;
      openSidebar: () => void;
      closeSidebar: () => void;
    }
  | undefined
>(undefined);

interface SidebarProviderProps {
  children: ReactNode;
}

export const SidebarProvider = ({ children }: SidebarProviderProps) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const openSidebar = () => setSidebarOpen(true);
  const closeSidebar = () => setSidebarOpen(false);

  return (
    <SidebarContext.Provider
      value={{ sidebarOpen, toggleSidebar, openSidebar, closeSidebar }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error('cannot use SidebarContext outside of SidebarProvider');
  }

  return context;
};
