export enum Warehouses {
  TRUG = 'TRUG',
  SYDM01 = 'SYDM01',
}

export enum Operator {
  LESS_THAN = '<',
  GREATER_THAN = '>',
  EQUALS = '=',
}

export interface SkuHazardousMaterials {
  adgClass?: string;
  ghsUnNumber?: string;
  unitQuantity?: string;
  unitOfMeasure?: string;
  ghsCategory?: string;
  ghsClass?: string;
}

export interface SkuAttributes {
  colour?: string;
  size?: string;
  brand?: string;
}

export interface SkuFootprint {
  weight?: number;
  height?: number;
  width?: number;
  length?: number;
  unitOfMeasure: 'each';
  uomQuantity: number;
}

export interface SkuInventory {
  sku_id: string;
  seller_code: string;
  warehouse_code: Warehouses;
  quantity_on_hand: number;
  quantity_on_sale: number;
  quantity_unallocated: number;
  quantity_on_po: number;
  oversized: boolean;
}

export interface SkuIdentifier {
  type: string;
  identifier: string;
}

export interface Sku {
  uuid: string;
  id: string;
  name: string;
  barcode: string;
  sellerCode: string;
  department?: string;
  category?: string;
  productGroup?: string;
  subType?: string;
  imageUrl?: string;
  countryOfOrigin?: string;
  costPrice?: number;
  retailPrice?: number;
  bulky: boolean;
  dangerousGoods: boolean;
  hazardousMaterials: SkuHazardousMaterials;
  attributes: SkuAttributes;
  footprint: SkuFootprint[];
  reworkCodes: string[];
  expiryDate?: number;
  bestBefore: boolean;
  expiry: boolean;
  createdAt?: number;
  updatedAt?: number;
}

export interface FbcSkuInventorySkuDetail {
  sellerCode: string;
  createdAt: number;
  updatedAt: number;
  barcode: string;
  uuid: string;
  id: string;
  name: string;
  category: string;
  imageUrl: string;
  costPrice: string | null;
  retailPrice: string | null;
}

export interface FbcSkuInventory {
  warehouse: Warehouses;
  sku: FbcSkuInventorySkuDetail;
  quantityOnHand: number;
  quantityOnSale: number;
  quantityUnallocated: number;
  quantityOnPo: number;
  expiryDate: number | null;
  oversized: boolean;
}

export interface FbcSkuInventoryReplenishment {
  id: string;
  name: string;
  soldLast7Days: number | null;
  soldLast30Days: number | null;
  soldLast90Days: number | null;
  currentInventoryAvailableToSell: number | null;
  currentInventoryOnPo: number | null;
  weeklySellThroughRate: number | null;
  weeksOfCover: number | null;
  recommendedReplen: number | null;
}

export interface PoItem {
  sku: string;
  wmsSkuId: string;
  seller: string;
  skuName: string;
  barcode: string;
  quantity: number;
}

export enum PoStatus {
  CREATED = 'created',
  ACCEPTED = 'accepted',
  RECEIVING = 'receiving',
  CLOSED = 'closed',
  REJECTED = 'rejected',
  CANCELLED = 'cancelled',
}

export interface Po {
  id: string;
  poNumber: string;
  seller: string;
  warehouse: Warehouses;
  expectedAt: number;
  createdAt: number;
  status: PoStatus;
  items: PoItem[];
}

export enum FulfilmentStatus {
  CREATED = 'created',
  ACCEPTED = 'accepted',
  PICKING = 'picking',
  SHIPPED = 'shipped',
  REJECTED = 'rejected',
  CANCELLED = 'cancelled',
}

export interface Account {
  seller: {
    code: string;
    name: string;
  };
}

export interface Delivery {
  address: {
    postalCode: string;
  };
}

export interface Consignment {
  id: string;
}

export interface Fulfilment {
  id: string;
  orderId: string;
  wmsOrderCode: string;
  warehouse: Warehouses;
  status: FulfilmentStatus;
  createdAt: number;
  account: Account;
  delivery: Delivery;
  consignments: Consignment[];
  fbcSellerCode: string;
  fbcSellerName: string;
}

export interface FulfilmentDetailDeliveryAddress {
  businessName: string;
  streetAddress1: string;
  streetAddress2: string;
  locality: string;
  region: string;
  postalCode: string;
  country: string;
}

export interface FulfilmentDetailDelivery {
  address: FulfilmentDetailDeliveryAddress;
  name: string;
  email: string;
  phone: string;
  instruction: string;
}

export interface FulfilmentDetailRequestedLine {
  sku: string;
  seller: string;
  barcode: string;
  name: string;
  quantity: number;
  orderLineNumber: number;
  lineValue: number;
}

export interface FulfilmentDetailArticleItem {
  sku: string;
  shippedQuantity: number;
  weightKilograms: number;
  heightCentimetres: number;
  widthCentimetres: number;
  lengthCentimetres: number;
  createdAt: number;
  updatedAt: number;
  seller: string;
}

export interface FulfilmentDetailArticle {
  uuid: string;
  id: string;
  barcode: string;
  weightKilograms: number;
  heightCentimetres: number;
  widthCentimetres: number;
  lengthCentimetres: number;
  items: FulfilmentDetailArticleItem[];
  dateLabelPrinted: string;
  createdAt: number;
  updatedAt: number;
}

export interface FulfilmentDetailConsignment {
  uuid: string;
  id: string;
  trackingId: string;
  trackingUrl: string | null;
  carrierService: string;
  articles: FulfilmentDetailArticle[];
  createdAt: number;
  updatedAt: number;
}

export interface FulfilmentDetail {
  uuid: string;
  id: string;
  orderId: string;
  channel: string;
  warehouse: Warehouses;
  wmsOrderCode: string;
  carrier: string;
  service: string;
  delivery: FulfilmentDetailDelivery;
  requestedLines: FulfilmentDetailRequestedLine[];
  payload: {
    customerId?: string;
    cncStoreId?: string | null;
    batchType?: string;
    serviceType?: string;
    parcelLocker?: boolean;
  };
  consignments: FulfilmentDetailConsignment[];
  authorityToLeave: boolean;
  safeDrop: boolean;
  status: FulfilmentStatus;
  createdAt: number;
  updatedAt: number;
}

export enum JobStatus {
  CREATED = 'created',
  IN_FLIGHT = 'in_flight',
  QUEUED = 'queue',
  FAILED_TO_QUEUE = 'failed_to_queue',
  DONE = 'done',
  ERROR = 'error',
  CANCELLED = 'cancelled',
}

export interface ImportJobResultsSummary {
  created?: number;
  updated?: number;
  skipped?: number;
  errored?: number;
  invalid?: number;
}

export interface Job {
  uuid: string;
  type: 'sku.import' | 'asn.import' | 'fulfilment.import';
  status: JobStatus;
  inputFilename: string | null;
  hasResultsFile: boolean;
  resultsSummary: ImportJobResultsSummary;
  error: string | null;
  createdAt: number;
  completedAt: number;
}

export interface SortParams {
  key: string;
  direction: 'ASC' | 'DESC';
}

export interface PaginateParams {
  offset: number;
  limit: number;
}

interface PaginatedResponse {
  count: number;
  total: number;
  pagination: PaginateParams;
  sort: SortParams;
}

export interface ListFbcSkusRequest extends Partial<PaginateParams> {
  seller?: string;
  id?: string;
  name?: string;
  category?: string;
  createdFrom?: number;
  createdTo?: number;
  updatedFrom?: number;
  updatedTo?: number;
  sort_key?:
    | 'id'
    | 'seller'
    | 'warehouse'
    | 'barcode'
    | 'name'
    | 'category'
    | 'status'
    | 'expectedAt'
    | 'createdAt'
    | 'updatedAt';
  sort_dir?: 'ASC' | 'DESC';
}

export interface PaginatedSkuResponse extends PaginatedResponse {
  skus: Sku[];
}

export interface GetSkuResponse {
  sku: Sku & { inventory: SkuInventory[]; identifiers: SkuIdentifier[] };
}

export interface ListFbcSkuInventoryRequest extends Partial<PaginateParams> {
  seller?: string;
  skuId?: string;
  skuName?: string;
  warehouse?: string;
  quantityUnallocated?: number;
  quantityUnallocatedOperator?: Operator;
  quantityOnPo?: number;
  quantityOnPoOperator?: Operator;
  sort_key?:
    | 'warehouse'
    | 'quantityOnHand'
    | 'quantityOnSale'
    | 'quantityUnallocated'
    | 'quantityOnPo'
    | 'expiryDate';
  sort_dir?: 'ASC' | 'DESC';
}

export interface PaginatedFbcSkuInventoryResponse extends PaginatedResponse {
  soh: FbcSkuInventory[];
}

export interface ListFbcSkuInventoryReplenishmentRequest
  extends Partial<PaginateParams> {
  seller?: string;
  skuId?: string;
  skuName?: string;
  sort_key?:
    | 'id'
    | 'name'
    | 'soldLast7Days'
    | 'soldLast30Days'
    | 'soldLast90Days'
    | 'currentInventoryAvailableToSell'
    | 'currentInventoryOnPo'
    | 'weeklySellThroughRate'
    | 'weeksOfCover'
    | 'recommendedReplen';
  sort_dir?: 'ASC' | 'DESC';
}

export interface PaginatedFbcSkuInventoryReplenishmentResponse
  extends PaginatedResponse {
  soh: FbcSkuInventoryReplenishment[];
}

export interface ListFbcPoRequest extends Partial<PaginateParams> {
  id?: string;
  warehouses?: Warehouses;
  statuses?: PoStatus;
  expectedFrom?: number;
  expectedTo?: number;
  createdFrom?: number;
  createdTo?: number;
  page?: number;
  limit?: number;
  sortBy?: string;
  sortDirection?: 'ASC' | 'DESC';
}

export interface PaginatedPoResponse extends PaginatedResponse {
  purchaseOrders: Po[];
}

export interface GetPoResponse {
  purchaseOrder: Po & {
    supplierCode: string;
    supplierName: string;
    outerContainers: number;
    poSplit: boolean;
  };
}

export interface ListFulfilmentsRequest extends Partial<PaginateParams> {
  orderId?: string;
  warehouse?: Warehouses;
  status?: PoStatus;
  consignmentId?: string;
  createdFrom?: number;
  createdTo?: number;
  page?: number;
  limit?: number;
  sortBy?: string;
  sortDirection?: 'ASC' | 'DESC';
}

export interface PaginatedFulfilmentResponse extends PaginatedResponse {
  fulfilments: Fulfilment[];
}

export interface GetFulfilmentResponse {
  fulfilment: FulfilmentDetail;
}

export interface PaginatedJobResponse extends PaginatedResponse {
  jobs: Job[];
}

export interface Error {
  message: string;
  field: string | null;
}

export interface ErrorResponse {
  errors: Error[];
}

export interface SuccessResponse {
  success: true;
  message: string;
}
