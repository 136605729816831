import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export default function Copyright(props: any) {
  return (
    <Typography
      variant="caption"
      color="text.secondary"
      align="center"
      {...props}
    >
      &copy; Copyright 2006 - {new Date().getFullYear()}{' '}
      <Link href="https://catch.com.au">Catch.com.au</Link> Pty Ltd (ABN 22 149
      779 939). All Rights Reserved.
    </Typography>
  );
}
