import PageTitleWrapper from '@/components/PageTitleWrapper';
import {
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import Footer from '@/components/Footer';
import SkuTable from './SkuTable';
import { UploadFileOutlined } from '@mui/icons-material';
import { useState } from 'react';
import FileUploadModal from '@/components/FileUpload/FileUploadModal';
import SkuDetailModal from './SkuDetailModal';
import ImportJobListModal, { ModalAlert } from '@/pages/ImportJobListModal';

export default function SkusPage() {
  const theme = useTheme();
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [uploadHistoryModalOpen, setUploadHistoryModalOpen] = useState(false);
  const [showUploadSuccessfulAlert, setShowUploadSuccessfulAlert] =
    useState(false);
  const [skuDetailId, setSkuDetailId] = useState<string | null>(null);

  const uploadSuccessAlert: ModalAlert = {
    severity: 'info',
    title: 'SKU upload',
    message:
      'File has been successfully uploaded for processing. Please check its progress below and use the refresh button at the bottom to see latest update.',
  };

  return (
    <>
      <title>FBC SKUs</title>
      <PageTitleWrapper>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <div>
            <Typography variant="h3" component="h3" gutterBottom>
              FBC SKUs
            </Typography>
            <Typography variant="subtitle2">
              A list of your SKUs which are Fulfilled by Catch.
            </Typography>
          </div>
          <div>
            <Button
              color="secondary"
              disabled={uploadHistoryModalOpen}
              sx={{ mr: 1 }}
              onClick={() => setUploadHistoryModalOpen(true)}
            >
              Upload History
            </Button>
            <Button
              variant="outlined"
              disabled={uploadModalOpen}
              onClick={() => setUploadModalOpen(true)}
            >
              <UploadFileOutlined sx={{ paddingRight: theme.spacing(0.5) }} />
              Upload
            </Button>
          </div>
        </Stack>
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <SkuTable onSkuSelected={setSkuDetailId} />
          </Grid>
        </Grid>
      </Container>
      <Footer />

      <FileUploadModal
        isOpen={uploadModalOpen}
        close={() => setUploadModalOpen(false)}
        accept={{ 'text/csv': ['.csv'] }}
        title="Upload SKUs"
        uploadUrl="/warehouse/fbc/skus/import"
        failedMessage="Failed to upload SKUs to the warehouse."
        onUploadSuccessful={() => {
          setUploadModalOpen(false);
          setUploadHistoryModalOpen(true);
          setShowUploadSuccessfulAlert(true);
        }}
      />

      <ImportJobListModal
        isOpen={uploadHistoryModalOpen}
        close={() => setUploadHistoryModalOpen(false)}
        importType="sku"
        alert={uploadSuccessAlert}
        showAlert={showUploadSuccessfulAlert}
        onAlertClose={() => setShowUploadSuccessfulAlert(false)}
      />

      <SkuDetailModal id={skuDetailId} close={() => setSkuDetailId(null)} />
    </>
  );
}
