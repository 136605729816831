import { ListSubheader, List, Button, ListItem } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';

import { SubMenuWrapper } from '.';
import { useSidebar } from '@/contexts/SidebarContext';
import {
  AssessmentTwoTone,
  ProductionQuantityLimitsTwoTone,
  ShoppingBagTwoTone,
  WarehouseTwoTone,
} from '@mui/icons-material';
import { useAuth } from '@/contexts/AuthContext';
import CollapsibleMenu from '@/components/Menu/CollapsibleMenu';

export default function FbcMenu() {
  const { user } = useAuth();
  const { closeSidebar } = useSidebar();

  if (!user?.seller.fbcSellerCode) {
    return;
  }

  return (
    <List
      component="div"
      subheader={
        <ListSubheader component="div" disableSticky>
          Fulfilled by Catch
        </ListSubheader>
      }
    >
      <SubMenuWrapper>
        <List component="div">
          <ListItem component="div">
            <Button
              component={RouterLink}
              onClick={closeSidebar}
              to="/fbc/skus"
              startIcon={<ProductionQuantityLimitsTwoTone />}
            >
              SKUs
            </Button>
          </ListItem>
          <ListItem component="div">
            <Button
              component={RouterLink}
              onClick={closeSidebar}
              to="/fbc/po"
              startIcon={<WarehouseTwoTone />}
            >
              POs
            </Button>
          </ListItem>
          <ListItem component="div">
            <Button
              component={RouterLink}
              onClick={closeSidebar}
              to="/fbc/orders"
              startIcon={<ShoppingBagTwoTone />}
            >
              Orders
            </Button>
          </ListItem>
          <ListItem component="div">
            <CollapsibleMenu icon={<AssessmentTwoTone />} label={'Reports'}>
              <Button
                component={RouterLink}
                onClick={closeSidebar}
                to="/fbc/reports/inventory"
                startIcon={<AssessmentTwoTone />}
              >
                Inventory
              </Button>
              <Button
                component={RouterLink}
                onClick={closeSidebar}
                to="/fbc/reports/inventory-replenishment"
                startIcon={<AssessmentTwoTone />}
              >
                Inventory Replenishment
              </Button>
            </CollapsibleMenu>
          </ListItem>
        </List>
      </SubMenuWrapper>
    </List>
  );
}
