import FilteredList from '@/components/FilteredList';
import { FormConfig } from '@/components/FilteredList/FilterInput';
import {
  ErrorResponse,
  ListFbcPoRequest,
  PaginatedPoResponse,
  Po,
  PoStatus,
  Warehouses,
} from '@/types/warehouse';
import {
  TableColumnConfig,
  Pagination,
  Sort,
} from '@/components/FilteredList/ItemTable';
import { Link, Typography, capitalize } from '@mui/material';
import { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro';
import api from '@/utils/api';
import { unixToFormattedDate } from '@/utils/formatters';
import { removeEmptyValues } from '@/utils/utils';

interface PoFilter {
  id: string;
  warehouse: string;
  status: string;
  expectedRange: DateRange<Dayjs>;
  createdRange: DateRange<Dayjs>;
}

export interface PoTableProps {
  onPoSelected?: (id: string) => void;
}

export default function PoTable({ onPoSelected }: PoTableProps) {
  const filterForm: FormConfig = [
    [
      {
        type: 'text',
        key: 'id',
        label: 'ID',
      },
      {
        type: 'select',
        key: 'warehouse',
        label: 'Warehouse',
        options: Object.values(Warehouses).map((option) => ({
          label: option,
          value: option,
        })),
      },
      {
        type: 'select',
        key: 'status',
        label: 'Status',
        options: Object.values(PoStatus).map((option) => ({
          label: capitalize(option),
          value: option,
        })),
      },
    ],
    [
      {
        type: 'dateRange',
        key: 'expectedRange',
        labelStartDate: 'Expected From',
        labelEndDate: 'Expected To',
      },
      {
        type: 'dateRange',
        key: 'createdRange',
        labelStartDate: 'Created From',
        labelEndDate: 'Created To',
      },
    ],
  ];
  const columns: TableColumnConfig<Po>[] = [
    {
      id: 'id',
      label: 'ID',
      render: (po) => (
        <Typography noWrap>
          {onPoSelected ? (
            <Link
              component="button"
              fontWeight="bold"
              onClick={() => onPoSelected(po.id)}
            >
              {po.id}
            </Link>
          ) : (
            po.id
          )}
        </Typography>
      ),
      sortable: true,
    },
    {
      id: 'poNumber',
      label: 'PO Number',
      render: (po) => <Typography noWrap>{po.poNumber}</Typography>,
      sortable: true,
    },
    {
      id: 'warehouse',
      label: 'Warehouse',
      render: (po) => po.warehouse,
      sortable: true,
    },
    {
      id: 'items',
      label: 'SKUs Count',
      render: (po) => po.items.length,
      sortable: true,
    },
    {
      id: 'status',
      label: 'Status',
      render: (po) => capitalize(po.status),
      sortable: true,
    },
    {
      id: 'expectedAt',
      label: 'Expected',
      render: (po) => unixToFormattedDate(po.expectedAt, true),
      sortable: true,
    },
    {
      id: 'createdAt',
      label: 'Created',
      render: (po) => unixToFormattedDate(po.createdAt),
      sortable: true,
    },
  ];
  const rowsPerPageOptions = [10, 25, 50, 100];
  const loadItems = async (
    filter: PoFilter,
    pagination: Pagination,
    sort: Sort,
  ) => {
    const requestParams = {
      id: filter.id ?? null,
      warehouses: filter.warehouse ?? null,
      statuses: filter.status ?? null,
      expectedFrom: filter.expectedRange?.[0]?.unix() ?? null,
      expectedTo:
        filter.expectedRange?.[1]?.add(1, 'day').subtract(1, 'second').unix() ??
        null,
      createdFrom: filter.createdRange?.[0]?.unix() ?? null,
      createdTo:
        filter.createdRange?.[1]?.add(1, 'day').subtract(1, 'second').unix() ??
        null,
      page: pagination.page,
      limit: pagination.limit,
      sortBy: sort.key,
      sortDirection: sort.direction,
    } as ListFbcPoRequest;

    const response = await api.get<PaginatedPoResponse | ErrorResponse>(
      '/warehouse/fbc/po',
      {
        params: removeEmptyValues(requestParams),
      },
    );

    if ('errors' in response.data) {
      throw new Error(response.data.errors[0]?.message ?? '');
    }

    return {
      items: response.data.purchaseOrders,
      pagination: {
        page: pagination.page,
        limit: pagination.limit,
        count: response.data.total,
      },
      sort: {
        key: response.data.sort.key,
        direction: response.data.sort.direction,
      },
    } as {
      items: Po[];
      pagination: Pagination;
      sort: Sort;
    };
  };

  return (
    <FilteredList
      filterForm={filterForm}
      columns={columns}
      rowsPerPageOptions={rowsPerPageOptions}
      defaultSortKey="createdAt"
      loadItems={loadItems}
      getItemId={(po) => po.id}
    />
  );
}
