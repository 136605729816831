import { RefreshTwoTone } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { ReactNode } from 'react';

export interface TableColumnConfig<T> {
  id: string;
  label: string | ReactNode;
  sortable: boolean;
  render: (item: T) => ReactNode;
}

export interface Sort {
  key: string;
  direction: 'ASC' | 'DESC';
}

export interface Pagination {
  page: number;
  limit: number;
  count: number;
}

export interface ItemTableProps<T> {
  isLoading: boolean;
  error: string | null;
  emptyMessage: string;
  maxHeight?: number | string;
  stickyHeader?: boolean;
  items: T[];
  getItemId: (item: T) => string;
  columns: TableColumnConfig<T>[];
  sort: Sort;
  onSortChange: (newSort: Sort) => void;
  pagination: Pagination;
  onPaginationChange: (newPagination: Pagination) => void;
  rowsPerPageOptions: number[];
  onReload?: () => void;
}

export default function ItemTable<T>({
  isLoading,
  error,
  emptyMessage,
  maxHeight,
  stickyHeader,
  items,
  getItemId,
  columns,
  sort,
  onSortChange,
  pagination,
  onPaginationChange,
  rowsPerPageOptions,
  onReload,
}: ItemTableProps<T>) {
  const handleSortChange = (key: string) => () => {
    const currentlyAscending = sort.key === key && sort.direction === 'ASC';
    onSortChange({
      key,
      direction: currentlyAscending ? 'DESC' : 'ASC',
    });
  };

  const handlePageChange = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    onPaginationChange({
      ...pagination,
      page: newPage,
    });
  };

  const handleRowsPerPageChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    onPaginationChange({
      ...pagination,
      limit: newLimit,
    });
  };

  return (
    <>
      <TableContainer sx={{ maxHeight }}>
        <Table stickyHeader={stickyHeader}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={`th-${column.id}`}>
                  {column.sortable ? (
                    <TableSortLabel
                      active={sort.key === column.id}
                      direction={
                        sort.key === column.id
                          ? (sort.direction.toLowerCase() as Lowercase<
                              typeof sort.direction
                            >)
                          : 'asc'
                      }
                      onClick={handleSortChange(column.id)}
                    >
                      {column.label}
                      {sort.key === column.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {`sorted ${
                            sort.direction === 'DESC'
                              ? 'descending'
                              : 'ascending'
                          }`}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && error !== null ? (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    Failed to load data: {error || 'something went wrong'}.
                    Please try again.
                  </Alert>
                </TableCell>
              </TableRow>
            ) : isLoading ? (
              <TableRow>
                <TableCell align="center" colSpan={columns.length}>
                  <CircularProgress sx={{ margin: 2 }} />
                </TableCell>
              </TableRow>
            ) : !items.length ? (
              <TableRow>
                <TableCell align="center" colSpan={columns.length}>
                  {emptyMessage}
                </TableCell>
              </TableRow>
            ) : (
              items.map((item) => (
                <TableRow hover key={getItemId(item)}>
                  {columns.map((header) => (
                    <TableCell key={`item-${getItemId(item)}-${header.id}`}>
                      {header.render(item)}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack p={2} direction="row" justifyContent="flex-end" spacing={2}>
        {onReload ? (
          <IconButton onClick={onReload} disabled={isLoading}>
            <RefreshTwoTone />
          </IconButton>
        ) : null}
        <TablePagination
          component="div"
          count={pagination.count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={pagination.page}
          rowsPerPage={pagination.limit}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      </Stack>
    </>
  );
}
