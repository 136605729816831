import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  IconButton,
  Modal,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import UploadFileSharpIcon from '@mui/icons-material/UploadFileSharp';
import Dropzone from './Dropzone';
import { FileWithPath } from 'file-selector';
import { useState, useCallback, useEffect } from 'react';
import api from '@/utils/api';
import { SuccessResponse, ErrorResponse } from '@/types/warehouse';
import { AxiosError } from 'axios';
import { Accept } from 'react-dropzone';

interface FileUploadModalProps {
  isOpen: boolean;
  close: () => void;
  accept: Accept;
  title: string;
  uploadUrl: string;
  successMessage?: string;
  failedMessage?: string;
  onUploadSuccessful?: (response: SuccessResponse) => void;
}

export default function FileUploadModal({
  isOpen,
  close,
  accept,
  title,
  uploadUrl,
  successMessage = 'File has been uploaded successfully.',
  failedMessage = 'Failed to upload file.',
  onUploadSuccessful = () => {},
}: FileUploadModalProps) {
  const theme = useTheme();

  const [file, setFile] = useState<FileWithPath | null>(null);
  const [isUploading, setUploading] = useState<boolean>(false);
  const [uploadSucceeded, setUploadSucceeded] = useState<boolean | null>(null);
  const [errors, setErrors] = useState<string[]>([]);

  const clearUploadResult = useCallback(() => {
    setErrors([]);
    setUploadSucceeded(null);
  }, []);

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[]) => setFile(acceptedFiles[0]),
    [],
  );
  const onDelete = useCallback(() => {
    clearUploadResult();
    setFile(null);
  }, [clearUploadResult]);

  const onUpload = useCallback(async () => {
    if (isUploading || file === null) {
      return;
    }

    clearUploadResult();
    setUploading(true);

    const formData = new FormData();
    formData.set('file', file, file.name);

    try {
      const response = await api.post<SuccessResponse | ErrorResponse>(
        uploadUrl,
        formData,
      );

      if ('errors' in response.data) {
        setUploadSucceeded(false);
        setErrors(response.data.errors.map((error) => error.message));

        return;
      }

      setFile(null);
      setUploadSucceeded(true);
      onUploadSuccessful(response.data);
    } catch (e) {
      setUploadSucceeded(false);
      setErrors([
        e instanceof AxiosError
          ? e.response?.data?.message
          : e instanceof Error
            ? e.message
            : `${e}`,
      ]);
    } finally {
      setUploading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, isUploading, clearUploadResult]);

  useEffect(() => {
    if (!isOpen) {
      clearUploadResult();
    }
  }, [isOpen, clearUploadResult]);

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        clearUploadResult();
        close();
      }}
      aria-labelledby="file-upload-modal-title"
    >
      <Container
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        disableGutters
        maxWidth="xs"
      >
        <Card>
          <CardHeader
            id="file-upload-modal-title"
            title={title}
            action={
              <IconButton onClick={close}>
                <CloseTwoToneIcon fontSize="small" />
              </IconButton>
            }
            sx={{ padding: theme.spacing(2, 3) }}
          />
          <Divider />
          <CardContent sx={{ padding: theme.spacing(2, 3) }}>
            <Stack sx={{ width: '100%' }} spacing={2}>
              {uploadSucceeded !== null && (
                <Alert
                  severity={uploadSucceeded ? 'success' : 'error'}
                  onClose={clearUploadResult}
                >
                  <AlertTitle>
                    {uploadSucceeded ? 'Success' : 'Error'}
                  </AlertTitle>
                  {uploadSucceeded ? successMessage : failedMessage}
                  {errors.length > 0 && (
                    <ul>
                      {errors.map((error, index) => (
                        <li key={index}>{error}</li>
                      ))}
                    </ul>
                  )}
                </Alert>
              )}

              {!file ? (
                <Dropzone onDrop={onDrop} accept={accept} />
              ) : (
                <Card sx={{ maxWidth: '100%' }}>
                  <CardContent>
                    <Stack direction="row" alignItems="center">
                      <UploadFileSharpIcon
                        sx={{ marginRight: theme.spacing(1) }}
                      />
                      <div>
                        <Typography variant="h5" component="div">
                          {file.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {file.path}
                        </Typography>
                      </div>
                    </Stack>
                  </CardContent>
                  <CardActions>
                    <LoadingButton
                      size="small"
                      onClick={onUpload}
                      loading={isUploading}
                    >
                      Upload
                    </LoadingButton>
                    <Button
                      size="small"
                      color="error"
                      disabled={isUploading}
                      onClick={onDelete}
                    >
                      Remove
                    </Button>
                  </CardActions>
                </Card>
              )}
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
}
