import { List, Button, ListItem } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';

import { SubMenuWrapper } from '.';
import { useSidebar } from '@/contexts/SidebarContext';
import {
  DashboardTwoTone,
  HandshakeTwoTone,
  SellTwoTone,
} from '@mui/icons-material';
import { useAuth } from '@/contexts/AuthContext';
import { API_BASE_URL } from '@/utils/api';

export default function MainMenu() {
  const { closeSidebar } = useSidebar();
  const { miraklUrl } = useAuth();

  return (
    <List component="div">
      <SubMenuWrapper>
        <List component="div">
          <ListItem component="div">
            <Button
              component={RouterLink}
              onClick={closeSidebar}
              to="/"
              startIcon={<DashboardTwoTone />}
            >
              Dashboard
            </Button>
          </ListItem>
          <ListItem component="div">
            <Button
              component="a"
              href={`${API_BASE_URL}/redirect/partner-hub`}
              target="_blank"
              rel="noopener noreferrer"
              startIcon={<HandshakeTwoTone />}
            >
              Partner Hub
            </Button>
          </ListItem>
          {miraklUrl && (
            <ListItem component="div">
              <Button
                component="a"
                href={miraklUrl}
                target="_blank"
                rel="noopener noreferrer"
                startIcon={<SellTwoTone />}
              >
                Marketplace
              </Button>
            </ListItem>
          )}
        </List>
      </SubMenuWrapper>
    </List>
  );
}
