import {
  Alert,
  AlertProps,
  AlertTitle,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Divider,
  IconButton,
  Link,
  Modal,
  Typography,
  useTheme,
} from '@mui/material';
import { CloseTwoTone } from '@mui/icons-material';
import api, { API_BASE_URL } from '@/utils/api';
import {
  ErrorResponse,
  ImportJobResultsSummary,
  Job,
  JobStatus,
  PaginatedJobResponse,
} from '@/types/warehouse';
import ItemTable, {
  Pagination,
  Sort,
  TableColumnConfig,
} from '@/components/FilteredList/ItemTable';
import { useCallback, useEffect, useState } from 'react';
import { AxiosError, CanceledError } from 'axios';
import { unixToFormattedDate } from '@/utils/formatters';
import { exhaustiveSwitchCheck } from '@/utils/utils';

export interface ModalAlert {
  severity: AlertProps['severity'];
  title: string;
  message: string;
}

export interface ImportJobListModalProps {
  isOpen: boolean;
  close: () => void;
  importType: string;
  alert?: ModalAlert;
  showAlert?: boolean;
  onAlertClose?: () => void;
}

export default function ImportJobListModal({
  isOpen,
  close,
  importType,
  alert,
  showAlert = false,
  onAlertClose = () => {},
}: ImportJobListModalProps) {
  const theme = useTheme();
  const rowsPerPageOptions = [10, 25, 50, 100];

  const [loading, setLoading] = useState(false);
  const [isFetchingJobs, setIsFetchingJobs] = useState(false);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [jobs, setJobs] = useState<Job[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    page: 0,
    limit: rowsPerPageOptions[0],
    count: 0,
  });
  const [sort, setSort] = useState<Sort>({
    key: 'createdAt',
    direction: 'DESC',
  });

  const formatJobStatus = (status: JobStatus) => {
    switch (status) {
      case JobStatus.CREATED:
        return (
          <Chip
            label="Created"
            color="warning"
            variant="outlined"
            size="small"
          />
        );
      case JobStatus.IN_FLIGHT:
        return (
          <Chip
            label="Processing"
            color="warning"
            variant="outlined"
            size="small"
          />
        );
      case JobStatus.QUEUED:
        return (
          <Chip
            label="Queued"
            color="warning"
            variant="outlined"
            size="small"
          />
        );
      case JobStatus.FAILED_TO_QUEUE:
        return (
          <Chip
            label="Failed to queue"
            color="error"
            variant="outlined"
            size="small"
          />
        );
      case JobStatus.DONE:
        return (
          <Chip label="Done" color="success" variant="outlined" size="small" />
        );
      case JobStatus.ERROR:
        return (
          <Chip label="Error" color="error" variant="outlined" size="small" />
        );
      case JobStatus.CANCELLED:
        return (
          <Chip
            label="Cancelled"
            color="error"
            variant="outlined"
            size="small"
          />
        );
      default:
        exhaustiveSwitchCheck(status, true);
        return (
          <Chip label={status} color="info" variant="outlined" size="small" />
        );
    }
  };

  const formatResultsSummary = (summary: ImportJobResultsSummary): string => {
    let categories = Object.keys(summary) as (keyof ImportJobResultsSummary)[];
    categories = categories.filter(
      (category) => summary[category] && summary[category]! > 0,
    );

    if (categories.length === 0) {
      return '';
    }

    return categories
      .map((category) => `${summary[category]} ${category}`)
      .join(', ');
  };

  const columns: TableColumnConfig<Job>[] = [
    {
      id: 'inputFilename',
      label: 'Name',
      render: (job) => (
        <Typography noWrap>
          {job.inputFilename ??
            `import-${unixToFormattedDate(job.createdAt, true)}`}
        </Typography>
      ),
      sortable: false,
    },
    {
      id: 'status',
      label: 'Progress',
      render: (job) => formatJobStatus(job.status),
      sortable: true,
    },
    {
      id: 'resultsSummary',
      label: 'Results',
      render: (job) => {
        const results = [job.error, formatResultsSummary(job.resultsSummary)];
        const formattedResults = results
          .filter((result) => !!result)
          .join(' - ');
        return formattedResults || '-';
      },
      sortable: true,
    },
    {
      id: 'createdAt',
      label: 'Created At',
      render: (job) => unixToFormattedDate(job.createdAt),
      sortable: true,
    },
    {
      id: 'completedAt',
      label: 'Completed At',
      render: (job) =>
        job.completedAt ? unixToFormattedDate(job.completedAt) : '-',
      sortable: true,
    },
    {
      id: 'inputFile',
      label: 'Input File',
      render: (job) => (
        <Link
          href={`${API_BASE_URL}/warehouse/job/import/${job.uuid}/input`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Download
        </Link>
      ),
      sortable: false,
    },
    {
      id: 'resultsFile',
      label: 'Results File',
      render: (job) =>
        job.hasResultsFile ? (
          <Link
            href={`${API_BASE_URL}/warehouse/job/import/${job.uuid}/results`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download
          </Link>
        ) : (
          '-'
        ),
      sortable: false,
    },
  ];

  const closeModal = () => {
    close();
    onAlertClose();
  };

  const fetchJobs = useCallback(
    async (sort: Sort, pagination: Pagination, inBackground: boolean) => {
      if (isFetchingJobs && inBackground) {
        return;
      }

      if (isFetchingJobs && abortController) {
        abortController.abort();
      }

      if (!inBackground) {
        setLoading(true);
        setError(null);
      }

      let isCancelled = false;
      const controller = new AbortController();
      setAbortController(controller);
      setIsFetchingJobs(true);

      try {
        const { data } = await api.get<PaginatedJobResponse | ErrorResponse>(
          `/warehouse/job/import/list`,
          {
            params: {
              type: importType,
              page: pagination.page,
              limit: pagination.limit,
              sortBy: sort.key,
              sortDirection: sort.direction,
            },
            signal: controller.signal,
          },
        );

        if ('errors' in data) {
          throw new Error(data.errors[0]?.message ?? '');
        }

        setError(null);
        setJobs(data.jobs);
        setPagination({
          ...pagination,
          count: data.total,
        });
      } catch (e) {
        if (e instanceof CanceledError) {
          isCancelled = true;
        } else {
          setError(
            e instanceof AxiosError
              ? e.response?.data?.message
              : e instanceof Error
                ? e.message
                : `${e}`,
          );
        }
      } finally {
        if (!isCancelled) {
          // Clean up loading status only if the request was not cancelled
          setLoading(false);
          setIsFetchingJobs(false);
          setAbortController(null);
        }
      }
    },
    [importType, isFetchingJobs, abortController],
  );

  useEffect(() => {
    if (isOpen) {
      fetchJobs(sort, pagination, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, sort, pagination.page, pagination.limit]);

  // Set up automatic background refresh
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    const scheduleFetch = () => {
      const hasPendingJob = jobs.some((job) =>
        [JobStatus.CREATED, JobStatus.QUEUED, JobStatus.IN_FLIGHT].includes(
          job.status,
        ),
      );

      timeout = setTimeout(
        () => {
          fetchJobs(sort, pagination, true);
        },
        hasPendingJob ? 10000 : 60000,
      );
    };
    const stopScheduleFetch = () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
    };

    if (isOpen && !isFetchingJobs) {
      scheduleFetch();
    }

    return stopScheduleFetch;
  }, [isOpen, isFetchingJobs, fetchJobs, sort, pagination, jobs]);

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="file-upload-modal-title"
    >
      <Container
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        disableGutters
        maxWidth="lg"
      >
        <Card>
          <CardHeader
            id="import-history-modal-title"
            title="Import History"
            action={
              <>
                <Button
                  variant="outlined"
                  sx={{ mr: 2 }}
                  onClick={() => fetchJobs(sort, pagination, false)}
                  disabled={loading}
                >
                  Refresh
                </Button>
                <IconButton onClick={closeModal}>
                  <CloseTwoTone fontSize="small" />
                </IconButton>
              </>
            }
            sx={{ padding: theme.spacing(2, 3) }}
          />
          <Divider />
          <CardContent>
            {alert && showAlert && (
              <Alert
                severity={alert.severity}
                onClose={onAlertClose}
                sx={{ marginBottom: theme.spacing(2) }}
              >
                <AlertTitle>{alert.title}</AlertTitle>
                {alert.message}
              </Alert>
            )}
            <ItemTable
              isLoading={loading}
              error={error}
              emptyMessage="No data"
              maxHeight="65vh"
              stickyHeader
              items={jobs}
              getItemId={(job) => job.uuid}
              columns={columns}
              sort={sort}
              onSortChange={setSort}
              pagination={pagination}
              onPaginationChange={setPagination}
              rowsPerPageOptions={rowsPerPageOptions}
            />
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
}
