import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  styled,
} from '@mui/material';
import { ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';
const StyledAccordion = styled(Accordion)(
  ({ theme }) => `
    width: 100%;
    background-color: transparent;
    .MuiAccordionSummary-root {
      padding: 0;
      &.Mui-expanded {
        .MuiButton-endIcon {
          transform: rotate(180deg);
        }
      }
      .MuiAccordionSummary-content .MuiButton-root .MuiButton-endIcon {
        transition: ${theme.transitions.create(['color', 'transform'])};
      }
      .MuiAccordionSummary-content {
        margin: 0;
      }
    }
`,
);
export interface CollapsibleMenuProps {
  icon: ReactNode;
  label: string;
  expandOnPath?: string[];
  children: ReactNode;
}
export default function CollapsibleMenu({
  icon,
  label,
  expandOnPath = [],
  children,
}: CollapsibleMenuProps) {
  const location = useLocation();
  const isOnPath = expandOnPath && expandOnPath.includes(location.pathname);
  const [expanded, setExpanded] = useState(isOnPath);
  const handleChange = (_event: React.SyntheticEvent, expanded: boolean) => {
    setExpanded(expanded);
  };
  return (
    <StyledAccordion disableGutters expanded={expanded} onChange={handleChange}>
      <AccordionSummary>
        <Button startIcon={icon} endIcon={<ExpandMore />}>
          {label}
        </Button>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
  );
}
