import { ListSubheader, List, Button, ListItem } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';

import { SubMenuWrapper } from '.';
import { useSidebar } from '@/contexts/SidebarContext';
import { DashboardTwoTone, MonetizationOnTwoTone } from '@mui/icons-material';
import { useAuth } from '@/contexts/AuthContext';

export default function RetailMediaMenu() {
  const { user } = useAuth();
  const { closeSidebar } = useSidebar();

  if (!user?.seller.retailMediaEnabled) {
    return;
  }

  return (
    <List
      component="div"
      subheader={
        <ListSubheader component="div" disableSticky>
          Sponsored Search
        </ListSubheader>
      }
    >
      <SubMenuWrapper>
        <List component="div">
          <ListItem component="div">
            <Button
              component={RouterLink}
              onClick={closeSidebar}
              to="/retail-media/dashboard"
              startIcon={<DashboardTwoTone />}
            >
              Campaign Management
            </Button>
          </ListItem>
          <ListItem component="div">
            <Button
              component={RouterLink}
              onClick={closeSidebar}
              to="/retail-media/credits"
              startIcon={<MonetizationOnTwoTone />}
            >
              Credits
            </Button>
          </ListItem>
        </List>
      </SubMenuWrapper>
    </List>
  );
}
