import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ThemeProvider from './themes/ThemeProvider';
import { AuthProvider } from './contexts/AuthContext';

import DashboardPage from './pages/dashboard';
import LoginPage from './pages/login';
import ProtectedRoute from './components/ProtectedRoute';
import SidebarLayout from './layouts/SidebarLayout';
import SkusPage from './pages/skus';
import RetailMediaDashboardPage from './pages/retail-media/dashboard';
import { SidebarProvider } from './contexts/SidebarContext';
import RetailMediaCreditsPage from './pages/retail-media/credits';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import 'dayjs/locale/en-au';
import RetailMediaVendorCreditsPage from './pages/retail-media/vendor-credits';
import FullPageLayout from './layouts/FullPageLayout';
import PoPage from './pages/po';
import { Alert } from '@mui/material';
import OrderPage from './pages/order';
import InventoryReportPage from './pages/report/inventory';
import InventoryReplenishmentReportPage from '@/pages/report/inventory-replenishment';

export default function App() {
  return (
    <div>
      <ThemeProvider>
        <BrowserRouter>
          <AuthProvider>
            <SidebarProvider>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-au"
              >
                <Suspense>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <ProtectedRoute>
                          <SidebarLayout>
                            <DashboardPage />
                          </SidebarLayout>
                        </ProtectedRoute>
                      }
                    />

                    <Route path="/login" element={<LoginPage />} />

                    <Route
                      path="/retail-media/dashboard"
                      element={
                        <ProtectedRoute>
                          <SidebarLayout>
                            <RetailMediaDashboardPage />
                          </SidebarLayout>
                        </ProtectedRoute>
                      }
                    />

                    {/* unauthenticated route for vendors */}
                    <Route
                      path="/retail-media/credits/vendor/:uuid"
                      element={
                        <FullPageLayout>
                          <RetailMediaVendorCreditsPage />
                        </FullPageLayout>
                      }
                    />

                    <Route
                      path="/retail-media/credits"
                      element={
                        <ProtectedRoute>
                          <SidebarLayout>
                            <RetailMediaCreditsPage />
                          </SidebarLayout>
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="/fbc/skus"
                      element={
                        <ProtectedRoute>
                          <SidebarLayout>
                            <SkusPage />
                          </SidebarLayout>
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="/fbc/po"
                      element={
                        <ProtectedRoute>
                          <SidebarLayout>
                            <PoPage />
                          </SidebarLayout>
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="/fbc/orders"
                      element={
                        <ProtectedRoute>
                          <SidebarLayout>
                            <OrderPage />
                          </SidebarLayout>
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="/fbc/reports/inventory"
                      element={
                        <ProtectedRoute>
                          <SidebarLayout>
                            <InventoryReportPage />
                          </SidebarLayout>
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="/fbc/reports/inventory-replenishment"
                      element={
                        <ProtectedRoute>
                          <SidebarLayout>
                            <InventoryReplenishmentReportPage />
                          </SidebarLayout>
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="*"
                      element={
                        <FullPageLayout>
                          <Alert severity="error">Page not found.</Alert>
                        </FullPageLayout>
                      }
                    />
                  </Routes>
                </Suspense>
              </LocalizationProvider>
            </SidebarProvider>
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}
