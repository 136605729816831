import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from '../contexts/AuthContext';
import { API_BASE_URL } from '@/utils/api';

interface ProtectedRouteProps {
  children: ReactNode;
}

export default function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    const desiredRoute = [
      location.pathname,
      location.search,
      location.hash,
    ].join('');

    if (desiredRoute && desiredRoute !== '/') {
      sessionStorage.setItem('redirectTo', desiredRoute);
      window.location.replace(`${API_BASE_URL}/login`);

      return;
    }

    return <Navigate to="/login" replace />;
  }

  return children;
}
