import { useEffect, useMemo, useState } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ThemeName, themeByName } from '.';

export default function ThemeProviderWrapper(props: any) {
  const getAppTheme = (): ThemeName => {
    let name = localStorage.getItem('appTheme');

    return name && name in themeByName ? (name as ThemeName) : 'MainTheme';
  };

  const [name] = useState<ThemeName>(getAppTheme());
  const theme = useMemo(() => themeByName[name], [name]);

  useEffect(() => localStorage.setItem('appTheme', name), [name]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
}
