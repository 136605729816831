import { CSSProperties } from 'react';

import { MainTheme } from './schemes/MainTheme';

export const themeByName = {
  MainTheme,
} as const;

export type ThemeName = keyof typeof themeByName;

declare module '@mui/material/styles' {
  interface Theme {
    colors: {
      gradients: {
        blue1: string;
        blue2: string;
        blue3: string;
        blue4: string;
        blue5: string;
        orange1: string;
        orange2: string;
        orange3: string;
        purple1: string;
        purple3: string;
        pink1: string;
        pink2: string;
        green1: string;
        green2: string;
        black1: string;
        black2: string;
      };
      shadows: {
        success: string;
        error: string;
        primary: string;
        warning: string;
        info: string;
      };
      alpha: {
        white: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
        };
        trueWhite: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
        };
        black: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
        };
      };
      secondary: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      primary: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      success: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      warning: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      error: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      info: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
    };
    general: {
      reactFrameworkColor: CSSProperties['color'];
      borderRadiusSm: string;
      borderRadius: string;
      borderRadiusLg: string;
      borderRadiusXl: string;
    };
    sidebar: {
      background: CSSProperties['color'];
      boxShadow: CSSProperties['color'];
      width: string;
      textColor: CSSProperties['color'];
      dividerBg: CSSProperties['color'];
      menuItemColor: CSSProperties['color'];
      menuItemColorActive: CSSProperties['color'];
      menuItemBg: CSSProperties['color'];
      menuItemBgActive: CSSProperties['color'];
      menuItemIconColor: CSSProperties['color'];
      menuItemIconColorActive: CSSProperties['color'];
      menuItemHeadingColor: CSSProperties['color'];
    };
    header: {
      height: string;
      background: CSSProperties['color'];
      boxShadow: CSSProperties['color'];
      textColor: CSSProperties['color'];
    };
  }

  interface ThemeOptions {
    colors: {
      gradients: {
        blue1: string;
        blue2: string;
        blue3: string;
        blue4: string;
        blue5: string;
        orange1: string;
        orange2: string;
        orange3: string;
        purple1: string;
        purple3: string;
        pink1: string;
        pink2: string;
        green1: string;
        green2: string;
        black1: string;
        black2: string;
      };
      shadows: {
        success: string;
        error: string;
        primary: string;
        warning: string;
        info: string;
      };
      alpha: {
        white: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
        };
        trueWhite: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
        };
        black: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
        };
      };
      secondary: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      primary: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      success: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      warning: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      error: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      info: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
    };

    general: {
      reactFrameworkColor: CSSProperties['color'];
      borderRadiusSm: string;
      borderRadius: string;
      borderRadiusLg: string;
      borderRadiusXl: string;
    };
    sidebar: {
      background: CSSProperties['color'];
      boxShadow: CSSProperties['color'];
      width: string;
      textColor: CSSProperties['color'];
      dividerBg: CSSProperties['color'];
      menuItemColor: CSSProperties['color'];
      menuItemColorActive: CSSProperties['color'];
      menuItemBg: CSSProperties['color'];
      menuItemBgActive: CSSProperties['color'];
      menuItemIconColor: CSSProperties['color'];
      menuItemIconColorActive: CSSProperties['color'];
      menuItemHeadingColor: CSSProperties['color'];
    };
    header: {
      height: string;
      background: CSSProperties['color'];
      boxShadow: CSSProperties['color'];
      textColor: CSSProperties['color'];
    };
  }
}
