import Scrollbar from '@/components/Scrollbar';
import { useSidebar } from '@/contexts/SidebarContext';

import { Box, Drawer, styled, Divider, useTheme, Theme } from '@mui/material';

import SidebarMenu from './SidebarMenu';
import Logo from '@/components/Logo';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
`,
);

export default function Sidebar() {
  const { sidebarOpen, toggleSidebar } = useSidebar();
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme<Theme>();

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            md: 'inline-block',
          },
          position: 'fixed',
          left: 0,
          top: 0,
          background: theme.colors.secondary.main,
          boxShadow:
            theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none',
        }}
      >
        <Scrollbar>
          <Box my={theme.spacing(2)} mx={theme.spacing(4)}>
            <Logo />
          </Box>
          <Divider
            sx={{
              mx: theme.spacing(2),
              background: theme.colors.alpha.trueWhite[10],
            }}
          />
          <SidebarMenu />
        </Scrollbar>
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`,
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarOpen}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            background: theme.colors.secondary.main,
          }}
        >
          <Scrollbar>
            <Box my={theme.spacing(2)} mx={theme.spacing(4)}>
              <Logo />
            </Box>
            <Divider
              sx={{
                mt: theme.spacing(3),
                mx: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10],
              }}
            />
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}
