import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Navigate } from 'react-router-dom';

import Copyright from '../components/Copyright';
import { useAuth } from '../contexts/AuthContext';
import Logo from '../components/Logo';
import { API_BASE_URL } from '../utils/api';
import { LoadingButton } from '@mui/lab';

export default function LoginPage() {
  const { isAuthenticated } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);

  const hasError = new URLSearchParams(window.location.search).has('error');

  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: 'url(/login-background.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          sx={{
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Logo />
          <Typography component="h1" variant="h4" sx={{ my: 2 }}>
            Seller Portal
          </Typography>
          <LoadingButton
            component="a"
            href={`${API_BASE_URL}/login`}
            type="button"
            variant="contained"
            color="primary"
            size="large"
            sx={{ mt: 2 }}
            style={{
              boxShadow: 'none',
              textTransform: 'none',
              letterSpacing: 'initial',
            }}
            loading={loading}
            onClick={() => setLoading(true)}
          >
            Sign in with Marketplace
          </LoadingButton>
          {hasError && (
            <Typography color="error" variant="subtitle1" sx={{ mt: 1 }}>
              Something went wrong! Please try again.
            </Typography>
          )}
          <Copyright sx={{ mt: 2 }} />
        </Box>
      </Grid>
    </Grid>
  );
}
