export const createCurrencyFormatter = (currency: string) =>
  new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency,
  });

export const unixToFormattedDate = (
  timestamp?: number,
  dateOnly: boolean = false,
): string => {
  if (!timestamp) {
    return '-';
  }

  const date = new Date(timestamp * 1000);
  if (dateOnly) {
    return date.toLocaleDateString();
  }

  return date.toLocaleString();
};
