import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Theme,
  useTheme,
} from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { useRef } from 'react';

export interface TextInput {
  type: 'text';
  key: string;
  label: string;
}

export interface SelectInput {
  type: 'select';
  key: string;
  label: string;
  options: { label: string; value: string }[];
}

export interface DateRangeInput {
  type: 'dateRange';
  key: string;
  labelStartDate: string;
  labelEndDate: string;
}

export interface OptionAndTextInput {
  type: 'optionAndText';
  key: string;
  label: string;
  inputType?: 'text' | 'number';
  optionKey: string;
  options: { label: string; value: string }[];
}

export type InputConfig =
  | TextInput
  | SelectInput
  | DateRangeInput
  | OptionAndTextInput;

export type FormConfig = InputConfig[][];

export interface FilterInputProps<T extends Record<string, any>> {
  filter: T;
  onFilterChange: (filter: T) => void;
  onFormSubmit: (filter: T) => void;
  formConfig: FormConfig;
  isLoading: boolean;
}

export const getEmptyFilterFromFormConfig = <
  Filter extends Record<string, any>,
>(
  formConfig: FormConfig,
): Filter => {
  const emptyFilter: Record<string, any> = {};
  for (const row of formConfig) {
    for (const inputConfig of row) {
      emptyFilter[inputConfig.key] = '';
      if (inputConfig.type === 'dateRange') {
        emptyFilter[inputConfig.key] = [null, null];
      }
      if (inputConfig.type === 'optionAndText') {
        emptyFilter[inputConfig.optionKey] = inputConfig.options[0].value;
      }
    }
  }

  return emptyFilter as Filter;
};

export default function FilterInput<T extends Record<string, any>>({
  filter,
  onFilterChange,
  onFormSubmit,
  formConfig,
  isLoading,
}: FilterInputProps<T>) {
  const theme = useTheme<Theme>();
  const formRef = useRef<HTMLFormElement>(null);

  const getInputElementFromInputConfig = (inputConfig: InputConfig) => {
    let inputElement = <></>;

    if (inputConfig.type === 'text') {
      inputElement = (
        <TextField
          variant="outlined"
          value={filter[inputConfig.key] ?? ''}
          onChange={(e) =>
            onFilterChange({
              ...filter,
              [inputConfig.key]: e.target.value || '',
            })
          }
          disabled={isLoading}
          label={inputConfig.label}
        />
      );
    } else if (inputConfig.type === 'select') {
      inputElement = (
        <>
          <InputLabel
            id={`${inputConfig.key}-input-label`}
            disabled={isLoading}
          >
            {inputConfig.label}
          </InputLabel>
          <Select
            value={filter[inputConfig.key]}
            onChange={(e) =>
              onFilterChange({
                ...filter,
                [inputConfig.key]: e.target.value || '',
              })
            }
            disabled={isLoading}
            label={inputConfig.label}
            labelId={`${inputConfig.key}-input-label`}
          >
            <MenuItem
              value=""
              key={`${inputConfig.key}-option-none`}
              sx={{ fontStyle: 'italic' }}
            >
              None
            </MenuItem>
            {inputConfig.options.map((option) => (
              <MenuItem
                value={option.value}
                key={`${inputConfig.key}-option-${option.value}`}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </>
      );
    } else if (inputConfig.type === 'dateRange') {
      inputElement = (
        <DateRangePicker
          localeText={{
            start: inputConfig.labelStartDate,
            end: inputConfig.labelEndDate,
          }}
          value={filter[inputConfig.key]}
          onChange={(value) =>
            onFilterChange({
              ...filter,
              [inputConfig.key]: value,
            })
          }
          disabled={isLoading}
        />
      );
    } else if (inputConfig.type === 'optionAndText') {
      inputElement = (
        <>
          <InputLabel
            id={`${inputConfig.key}-input-label`}
            disabled={isLoading}
          >
            {inputConfig.label}
          </InputLabel>
          <OutlinedInput
            type={inputConfig.inputType ?? 'text'}
            disabled={isLoading}
            startAdornment={
              <InputAdornment position="end">
                <FormControl size="small" variant="standard">
                  <Select
                    disableUnderline
                    value={filter[inputConfig.optionKey]}
                    onChange={(e) =>
                      onFilterChange({
                        ...filter,
                        [inputConfig.optionKey]: e.target.value || '',
                      })
                    }
                    disabled={isLoading}
                    sx={{ top: '2px' }}
                  >
                    {inputConfig.options.map((option) => (
                      <MenuItem
                        value={option.value}
                        key={`${inputConfig.optionKey}-option-${option.value}`}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </InputAdornment>
            }
            label={inputConfig.label}
            value={filter[inputConfig.key] ?? ''}
            onChange={(e) =>
              onFilterChange({
                ...filter,
                [inputConfig.key]: e.target.value || '',
              })
            }
          />
        </>
      );
    }

    return inputElement;
  };

  const resetFilter = () => {
    onFilterChange(getEmptyFilterFromFormConfig(formConfig));
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Both inputs for optionAndText type needs to be set or empty together.
    // If any one of them is empty, set both to empty.
    const cleanFilter = { ...filter };
    for (const row of formConfig) {
      for (const inputConfig of row) {
        if (inputConfig.type === 'optionAndText') {
          if (
            cleanFilter[inputConfig.key] === '' ||
            cleanFilter[inputConfig.optionKey] === ''
          ) {
            (cleanFilter as any)[inputConfig.key] = '';
            (cleanFilter as any)[inputConfig.optionKey] = '';
          }
        }
      }
    }

    onFormSubmit(cleanFilter);
  };

  return (
    <Box sx={{ margin: theme.spacing(2, 4) }}>
      <form onSubmit={handleFormSubmit} ref={formRef}>
        <Grid
          container
          rowSpacing={theme.spacing(1)}
          columnSpacing={theme.spacing(2)}
          justifyContent="space-around"
          alignItems="center"
        >
          {formConfig.map((row, i) => {
            const totalInputFieldsInRow =
              row.length +
              row.reduce(
                (total, inputConfig) =>
                  inputConfig.type === 'dateRange' ? total + 1 : total,
                0,
              );

            return row.map((inputConfig) => {
              // Get grid width, taking into account date range picker takes up
              // 2 input fields
              let width = Math.floor(12 / totalInputFieldsInRow);
              if (inputConfig.type === 'dateRange') {
                width = width * 2;
              }

              return (
                <Grid
                  item
                  xs={12}
                  md={width}
                  key={`input-field-${inputConfig.key}`}
                >
                  <FormControl variant="outlined" fullWidth={true}>
                    {getInputElementFromInputConfig(inputConfig)}
                  </FormControl>
                </Grid>
              );
            });
          })}

          <Grid item xs={12} textAlign="right">
            <Button
              variant="text"
              color="secondary"
              disabled={isLoading}
              sx={{ mr: 1 }}
              onClick={() => {
                resetFilter();
                setTimeout(() => formRef.current?.requestSubmit(), 0);
              }}
            >
              Clear
            </Button>
            <LoadingButton
              type="submit"
              variant="outlined"
              size="medium"
              disabled={isLoading}
              loading={isLoading}
            >
              Filter
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
