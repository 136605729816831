export const removeEmptyValues = <T extends Object>(obj: T): Partial<T> => {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => value !== null && value !== ''),
  ) as Partial<T>;
};

export const exhaustiveSwitchCheck = (
  option: never,
  silent: boolean = false,
): never => {
  if (silent) {
    console.error(`Unknown option: ${option}`);
    return option;
  }

  throw new Error(`Unknown option: ${option}`);
};
