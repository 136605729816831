import { Link } from 'react-router-dom';
import CatchLogo from '@/assets/images/catch-logo.svg';

export default function Logo(props: { width?: string | number }) {
  const width = props.width
    ? typeof props.width === 'number'
      ? `${props.width}px`
      : props.width
    : '140px';

  return (
    <Link to="/" style={{ textDecoration: 'none' }}>
      <img width={width} alt="Catch Logo" src={CatchLogo} />
    </Link>
  );
}
